@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-family: 'Merriweather', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    height: 100%;
    overflow: auto;
}

html {
    overflow: hidden;
    height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#app {
    background-color: #f1f1ee;
}

.card-colour {
    background-color: #ffffff;
}

.list-reset {
    list-style: none; 
    margin: 0rem; 
    padding: 0rem;
}

.primary {
    background-color: #d9e4dd;
    background-color: #e2e2e2;
}

.button-colour{
    background-color: #e6eef1;
}

.image-wrap {
    position: relative;
    min-height: 15rem;
}

.image {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
}

.card-image-wrap {
    position: relative;
    height: 0; 
    padding-top: 56.25%;
    overflow: hidden;
}

.card-image {
    top: 0; 
    left: 0;
    width: 100%; 
    height: 100%;
    position: absolute;
}

.link-colour {
    color: #1d2e34
}


